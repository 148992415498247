import * as React from "react"
import { Container } from 'react-bootstrap';
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <h1>404: Not Found</h1>
      <img src="https://media0.giphy.com/media/vLjXo36FZUJCE/giphy.gif" alt="Otter dancing with a fish" />
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>

  </Layout>
)

export default NotFoundPage
